@import url("@nimbus-ds/tokens/dist/css/variables.css");

@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --background: var(--color-light-neutral-surface);
}

/* The admin currently doesn't support dark mode. When it does, uncomment the following lines to provide dark mode support. */
/* @media (prefers-color-scheme: dark) {
  :root {
    --background: var(--color-dark-neutral-surface);
  }
} */

.draggable {
  user-select: none;
  /* Previne a seleção de texto */
  -webkit-user-drag: none;
  /* Previne comportamento de arrasto em navegadores WebKit */
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: initial;
}

html,
body {
  max-width: 100vw;
}

body {
  background-color: var(--background);
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}